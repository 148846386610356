jQuery( document ).ready(function() {
  /**
  * scroll classes
  * 1. detect top position
  * 2. detect up/down movement
  * NB: Regarder css, on donne la priorité à .scroll-top à cause du "rebond" sur ios...
  *
  */

  jQuery('body').addClass('scroll-top');
  var topPos = 0;
  var dowPos = 100;
  var scrollPos = topPos; // init
  jQuery(window).scroll(function() {
    var curScrollPos = jQuery(window).scrollTop();
    if(curScrollPos > topPos) {
      jQuery('body').removeClass('scroll-top');
    } else {
      jQuery('body').addClass('scroll-top');
    }
    if(curScrollPos < scrollPos) {
      jQuery('body').addClass('scroll-up');
      jQuery('body').removeClass('scroll-down');
    } else {
      jQuery('body').removeClass('scroll-up');
      jQuery('body').addClass('scroll-down');
    }
    if(curScrollPos > dowPos) {
      jQuery('nav').addClass('background-black');
    } else {
      jQuery('nav').removeClass('background-black');
    }
    scrollPos = curScrollPos;
  });

 // Select2 //

  jQuery('select').select2({
    width: '100%',
    minimumResultsForSearch: 1000
  });
  jQuery('.select2-selection__arrow').html('<i class="fa fa-angle-down"></i>');



  /**
   * scrollTo Function
   * https://stackoverflow.com/questions/7717527/smooth-scrolling-when-clicking-an-anchor-link
   *
   */
  jQuery('.js-scrollto').click(function() {
    target = jQuery.attr(this, 'href');
    jQuery('html, body').animate({
      scrollTop: jQuery(target).offset().top
    }, 500);
    return false;
  });
});
