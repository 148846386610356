import "slick-carousel/slick/slick-theme.scss";
import "slick-carousel/slick/slick.scss";

import "../../css/src/theme.scss";
import "popper.js";
import "bootstrap";
import "@fancyapps/fancybox";
import "select2";
import "./acreat/jquery.js";
import "./behaviors/slick.js";
import "./behaviors/js-cookie.js";
import "./behaviors/dropdown-hover.js";

import WebFont from 'webfontloader';
import Swiper from 'swiper';
import Slick from 'slick-carousel';
import Cookies from 'js-cookie';


jQuery( document ).ready(function() {

  jQuery(window).on('load', () => {
    WebFont.load({
      google: {
        families: ['Quicksand', 'Rock Salt',]
      }
    });
  });
  jQuery(".modal").modal("show");
});


if ($('.front .slick-container').children().length > 1) {
  $('.front .slick-container').slick({
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    speed: 1500,
    autoplaySpeed: 9000,
    autoplay: true,
    draggable: false,
    pauseOnHover: false,
    pauseOnFocus: false
  });
}


var themeStyle = Cookies.get("leschaisdesaintecroix.com-theme");

  jQuery("a.theme-btn").click(function() {
      Cookies.set("leschaisdesaintecroix.com-theme", jQuery(this).data("theme"), { expires: (90*24*3600) });
  });
    if(themeStyle == "nuit") {
        jQuery("body").removeClass("theme-jour").addClass("theme-nuit");
      } else {
    jQuery("body").removeClass("theme-nuit").addClass("theme-jour");
      }
