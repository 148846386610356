// Slick
Drupal.behaviors.slick = {
  attach(context) {
    const scrollSelector = "slick--scroll-";
    const showSelector = "slick--show-";

    jQuery(".slick:not(.slick--combined)", context).each((idx, elt) => {
      const options = {
        arrows: false,
        autoplaySpeed: 5000,
        mobileFirst: true
      };

      const responsive = {
        sm: {
          breakpoint: 576,
          settings: {}
        },
        md: {
          breakpoint: 768,
          settings: {}
        },
        lg: {
          breakpoint: 992,
          settings: {}
        },
        xl: {
          breakpoint: 1290,
          settings: {}
        }
      };

      if (jQuery(elt).hasClass("slick--autoplay")) {
        options.autoplay = true;
      }

      if (jQuery(elt).hasClass("slick--dots")) {
        options.dots = true;
      }

      if (jQuery(elt).hasClass("slick--fade")) {
        options.fade = true;
      }

      if (jQuery(elt).is(`[class*='${scrollSelector}']`)) {
        const classes = jQuery(elt)
          .attr("class")
          .split(/\s+/);

        classes.forEach(className => {
          if (className.indexOf(showSelector) === 0) {
            const parts = className.replace(showSelector, "").split(/-+/);

            switch (parts.length) {
              case 2:
                if (responsive[parts[0]]) {
                  responsive[parts[0]].settings.slidesToShow = Number.parseInt(
                    parts[1],
                    10
                  );
                }
                break;
              case 1:
                options.slidesToShow = Number.parseInt(parts[0], 10);
                break;
              default:
            }
          }
        });
      }

      if (jQuery(elt).is(`[class*='${scrollSelector}']`)) {
        const classes = jQuery(elt)
          .attr("class")
          .split(/\s+/);

        classes.forEach(className => {
          if (className.indexOf(scrollSelector) === 0) {
            const parts = className.replace(scrollSelector, "").split(/-+/);

            switch (parts.length) {
              case 2:
                if (responsive[parts[0]]) {
                  responsive[
                    parts[0]
                  ].settings.slidesToScroll = Number.parseInt(parts[1], 10);
                }
                break;
              case 1:
                options.slidesToScroll = Number.parseInt(parts[0], 10);
                break;
              default:
            }
          }
        });
      }

      // if (jQuery(elt).hasClass("slick--overflow")) {
      //   options.centerMode = false;
      //   options.centerPadding = "10vw";
      //   options.slidesToShow = 2;
      //   responsive.md.settings.slidesToShow = 1;
      // }

      if (jQuery(elt).hasClass("slick--multiple-items")) {
        responsive.xl.settings.slidesToShow = 3;
        responsive.lg.settings.slidesToShow = 3;
        responsive.md.settings.slidesToShow = 2;
        responsive.sm.settings.slidesToShow = 1;
        options.slidesToScroll = 1;


      };


      if (Object.values(responsive.sm.settings).length === 0) {
        delete responsive.sm;
      }
      if (Object.values(responsive.md.settings).length === 0) {
        delete responsive.md;
      }
      if (Object.values(responsive.lg.settings).length === 0) {
        delete responsive.lg;
      }
      if (Object.values(responsive.xl.settings).length === 0) {
        delete responsive.xl;
      }

      options.responsive = Object.values(responsive);

      options.prevArrow = jQuery(elt).data("slick-prev");
      options.nextArrow = jQuery(elt).data("slick-next");

      if (options.prevArrow || options.nextArrow) {
        options.arrows = true;
      }

      jQuery(elt).slick(options);
    });

    jQuery(".slick--combined", context).each((idx, elt) => {
      jQuery(".slick__nav", elt).append(
        jQuery(".slick__content img", elt).clone()
      );

      jQuery(".slick__content", elt).slick({
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        swipe: false
      });

      jQuery(".slick__nav", elt).slick({
        arrows: true,
        appendArrows: ".slick__arrows",
        prevArrow:
          '<button class="slick__prev btn btn-primary btn-square"><i class="fa fa-angle-up font-size-md"></i></button>',
        nextArrow:
          '<button class="slick__next btn btn-primary btn-square"><i class="fa fa-angle-down font-size-md"></i></button>',
        asNavFor: ".slick__content",
        dots: false,
        focusOnSelect: true,
        infinite: true,
        mobileFirst: true,
        slidesToScroll: 1,
        slidesToShow: 3,
        vertical: true
      });
    });
  }
};

